import classNames from 'classnames';
import Image from 'next/image';
import { Button } from '../../../components/ui/button/Button';
import type { ImageType } from '../../../types.d/storeTypes';
import styles from './NotFoundHero.module.scss';

export type NotFoundHeroProps = {
  title: string;
  subtitle: string;
  message: string;
  image: ImageType;
  imageMobile: ImageType;
  button: {
    title: string;
    url: string;
    newTab: boolean;
  };
};

export const NotFoundHero = ({
  title,
  subtitle,
  message,
  image,
  imageMobile,
  button,
}: NotFoundHeroProps) => {
  return (
    <section className={styles.notFoundHero}>
      <Image
        className={styles.notFoundHero__bannerImageDesktop}
        src={image.url}
        alt={image.title}
        title={image.title}
        fill
      ></Image>

      <Image
        className={styles.notFoundHero__bannerImageMobile}
        src={imageMobile.url}
        alt={imageMobile.title}
        title={imageMobile.title}
        width={imageMobile.width}
        height={imageMobile.height}
      ></Image>

      <div className={styles.notFoundHero__bannerContent}>
        {title && (
          <h1
            className={classNames(
              styles.notFoundHero__bannerTitle,
              'text-heading-h2-desktop',
              'text-heading-h2-mobile'
            )}
          >
            {title}
          </h1>
        )}
        {subtitle && (
          <h2
            className={classNames(
              styles.notFoundHero__bannerSubtitle,
              'text-heading-h2-desktop',
              'text-heading-h2-mobile'
            )}
          >
            {subtitle}
          </h2>
        )}
        {message && (
          <p
            className={classNames(
              styles.notFoundHero__bannerMessage,
              'text-heading-h4-desktop',
              'text-heading-h5-mobile'
            )}
          >
            {message}
          </p>
        )}
        {button.title && button.url && (
          <Button
            className={styles.notFoundHero__bannerButton}
            href={button.url}
            modifiers={['black']}
            target={button.newTab ? '_blank' : undefined}
          >
            {button.title}
          </Button>
        )}
      </div>
    </section>
  );
};
